.featuredCategoryImgWrapper {
	position: relative;
	width: 200px;
	height: 200px;
	z-index: 1;
	overflow: hidden;
}

.featuredCategoryImgWrapper::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(219, 229, 239, 0.3);
	z-index: 2;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	border-radius: 50%;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	transform-origin: center;
	-webkit-transition: transform 0.3s ease-in-out;
	-moz-transition: transform 0.3s ease-in-out;
	-ms-transition: transform 0.3s ease-in-out;
	-o-transition: transform 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out;
}

.featuredCategoryImgWrapper:hover::after {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

@media all and (max-width: 1279px) {
	.featuredCategories {
		padding-top: 0px !important;
		padding-bottom: 40px !important;
	}
}

@media all and (max-width: 425px) {
	.featuredCategories {
		padding-top: 0px !important;
		padding-bottom: 40px !important;
	}
}

@media all and (max-height: 600px) and (orientation: landscape) {
	.featuredCategories {
		padding-top: 0px !important;
		padding-bottom: 25px !important;
	}
}
