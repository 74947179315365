.product {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 3rem;
}

.phoneBtn {
	background-color: #2c60bd !important;
	color: white !important;
}

.chatBtn {
	background: #c62132;
	color: #ffff;
	padding-left: 2.1rem;
	padding-right: 2.1rem;
}

.image {
	width: 30px;
}

.chat_col {
	min-width: 300px;
	max-width: 400px;
}

@media screen and (min-width: 768px) and (max-width: 1025px) {
	.chat_col {
		min-width: 650px;
		max-width: 600px;
	}
}

.product_card {
	/* border-right: 1rem solid transparent !important; */
	padding: 0 1rem;
}

.horzontal_product,
.offer_product {
	align-items: center;
	text-align: center;
	justify-content: center;
}

.horzontal_product {
	flex-direction: row;
}

.offer_product .price {
	display: flex;
	justify-content: center;
}

.horzontal_product .price_wrapper {
	margin-left: 2rem;
}

@media screen and (max-width: 1024px) {
	.horzontal_product {
		flex-direction: column;
	}
}

@media screen and (max-width: 576px) {
	.chat_col {
		min-width: 100%;
	}
}

@media all and (max-width: 1279px) {
	.featuredProducts {
		padding-top: 29px !important;
	}
}

@media all and (max-width: 425px) {
	.featuredProducts {
		padding-top: 29px !important;
	}
}

@media all and (max-height: 600px) and (orientation: landscape) {
	.featuredProducts {
		padding-top: 29px !important;
	}
}
