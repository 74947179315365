.card {
	position: relative;
	border-color: var(--primary);
	z-index: 1;
}

.card:first-of-type {
	z-index: 1;
}

.card:nth-of-type(2) {
	z-index: 1;
}

.card:nth-of-type(3) {
	z-index: 1;
}

.card h2 {
	color: var(--primary);
}

.card::after,
.card::before {
	content: '';
	position: absolute;
	top: 50%;
	left: -0.75rem;
	transform: translateY(-50%);
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 50%;
	background-color: var(--white);
	border: 1px dashed var(--primary);
	/* z-index: 1; */
}

.card::after {
	left: initial;
	right: -0.75rem;
}
@media all and (max-width: 480px) {
	.card::after,
	.card::before {
		content: '';
		display: none;
	}
}
@media all and (max-width: 413px) {
	.specialIcons {
		width: 2rem;
		height: 2rem;
	}
	.specialText {
		font-size: 12px;
	}
}
