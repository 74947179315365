.hero_slider {
	position: relative;
}

.prev_icon,
.next_icon {
	position: absolute;
	top: 50%;
	left: 2.5%;
	transform: translateY(-50%);
	width: 30px;
	height: 30px;
	z-index: 2;
	background-color: white;
	width: 40px;
	height: 40px;
	padding: 7.5px;
}

.next_icon {
	left: initial;
	right: 2.5%;
}
